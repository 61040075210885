import instance from '@/service/interceptors'
import {Md5} from "ts-md5/dist/md5";
type Method = 'GET' | 'POST' |'get'|'post';// 'PUT' | 'DELETE'
type ResponseType = 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream';
import Qs from 'qs'
interface AxiosRequest {
    baseURL?: string;
    url: string;
    data?: any;
    params?: any;
    method?: Method;
    headers?: any;
    timeout?: number;
    responseType?: ResponseType;
	transformRequest?:any
}

// interface AxiosResponse {
//     data: any;
//     headers: any;
//     request?: any;
//     status: number;
//     statusText: string;
//     config: AxiosRequest;
// }
interface CustomResponse {
    readonly status: boolean;
    readonly message: string;
    data: any;
    origin?: any;
}
// interface resultObject = {
//   status: number
//   message: string
//   data: any,
//   origin:any
// }

class Abstract{
	public baseURL='//bbs.49you.com';//线上
	//public baseURL='http://bbstest.49you.com';//本地测试
	
	private apiAxios({ baseURL = this.baseURL, headers={'Content-Type':'application/x-www-form-urlencoded'}, method='get', url, data={}, params={}, responseType,transformRequest }: AxiosRequest): Promise<CustomResponse> {
		
		const token:any=localStorage.getItem('token');
		// if(token){
		// 	Object.assign(headers,{token:token});
		// }
		
		//'Content-Type': 'application/x-www-form-urlencoded'
			// 'Content-Type':'multipart/form-data'/
			//'multipart/form-data; boundary=something'
			//'application/x-www-form-urlencoded'
	        // url解析
	        // const _url = (url as string).split('.');
	        // url = getUrl(_url[0], _url[1]);
		if(!transformRequest&&url.indexOf('uploadImg')==-1){
			transformRequest=[function (data:any) {
				if(token){
					if(!(url.indexOf('login')>-1)&&!(url.indexOf('findPwd')>-1)&&!(url.indexOf('smsLogin')>-1)&&!(url.indexOf('phoneRegister')>-1)){
						data.token=token;
					}
				}
				// 对 data 进行任意转换处理
				return Qs.stringify(data)
			}]
		}
		data=this.getData(data);	
		params=this.getData(params);
	    return new Promise((resolve:any, reject:any) => {
			instance({
			    baseURL,
			    headers,
			    method,
			    url,
			    params,
			    data,
			    responseType,
				transformRequest
			}).then((res:any) => {
				if(res.status==200){
					if(res.data.status==1){
						resolve({
							status:1,
							message:res.data.msg,
							data:res.data?res.data.data:{},
							origin:res.data
						})
					}else{
						resolve({
							status:0,
							message:res.data.msg,
							data:res.data?res.data.data:{},
							origin:res.data
						})
					}
				}else{
					resolve({
						status:0,
						message:res.data.msg,
						data:res.data?res.data.data:{},
						origin:res.data
					})
				}
			}).catch((err:any) => {
				debugger;
			    reject({ status: 0, message:"请求失败", data: {}});
			});
	            
	    });
	}
	private objKeySort(obj:any){
		const newkey = Object.keys(obj).sort(); //先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
		const newObj=<any>{}; //创建一个新的对象，用于存放排好序的键值对
		let i:number;
		
		for (i = 0; i < newkey.length; i++) { //遍历newkey数组
			newObj[newkey[i]] = obj[newkey[i]]; //向新创建的对象中按照排好的顺序依次增加键值对
		}
		return newObj; //返回排好序的新对象
	}
	
	private getSign(s:any){
		const arry = this.objKeySort(s); //按key排序
		
		let str = <string>"";
		let i:any;

		for ( i in arry) { //读取键值
			const temp:any = arry[i];
			if (typeof(temp) == 'string') {
				str += temp;
			} else if (typeof(temp) == "object" && Object.prototype.toString.call(temp).toLowerCase() == "[object object]") {
				str += JSON.stringify(temp);
			}
		}
		const sn = str + "jlj*0a&%em#68x@8y^0&!#t^nd^rw2hx7egag301";
		console.log(sn,Md5.hashStr(sn));
		return Md5.hashStr(sn);	
	}
	
	private getData(data:any){
		
		//data.timestamp = (Date as any).parse(new Date()).toString().substr(0, 10);
		//data.sign=this.getSign(data)
		console.log(data);
		return data;
	}
	
	/**
	 * GET类型的网络请求
	 */
	protected getReq({ baseURL, headers, url, data, params, responseType }: AxiosRequest) {
	    return this.apiAxios({ baseURL, headers, method: 'GET', url, data, params, responseType });
	}
	
	/**
	 * POST类型的网络请求
	 */
	protected postReq({ baseURL, headers, url, data, params, responseType }: AxiosRequest) {
	    return this.apiAxios({ baseURL, headers, method: 'POST', url, data, params, responseType });
	}
	
	/**
	 * PUT类型的网络请求
	 */
	// protected putReq({ baseURL, headers, url, data, params, responseType }: AxiosRequest) {
	//     return this.apiAxios({ baseURL, headers, method: 'PUT', url, data, params, responseType });
	// }
	
	/**
	 * DELETE类型的网络请求
	 */
	// protected deleteReq({ baseURL, headers, url, data, params, responseType }: AxiosRequest) {
	//     return this.apiAxios({ baseURL, headers, method: 'DELETE', url, data, params, responseType });
	// }
	protected requestFile(data:any) {
		// let reqData=data.params;
		// reqData.timestamp=new Date().getTime();
		// reqData.ver='appbox_001';
		// reqData.sign=this.getSign(reqData);
		// let requestData = {
		//   url: data.url+'?'+this.parseParams(reqData),
		//   data: data.data,
		//   method: 'post',
		//   timeout: 300000,
		//   onUploadProgress: data.onUploadProgress || null,
		//   headers:{'Content-Type': 'multipart/form-data' },
		//   responseType: data.responseType || 'json'
		// };
		// return axios(requestData);
	}
  
	protected parseParams(data:any) {
		// try {
		//   var tempArr = [];
		//   for (var i in data) {
		//     var key = encodeURIComponent(i);
		//     var value = encodeURIComponent(data[i]);
		//     tempArr.push(key + '=' + value);
		//   }
		//   var urlParamsStr = tempArr.join('&');
		//   return urlParamsStr;
		// } catch (err) {
		  return '';
		//}
  }
  
  protected async submitPic ({ baseURL = this.baseURL, headers={ "Content-Type": "multipart/form-data" }, method='post', url, data={}, params={}, responseType,transformRequest}: AxiosRequest): Promise<CustomResponse> {
		const file=data;		
		data= new FormData();	
		
		if(file.file.size>500*1024){
			const blob=await this.filePress(file.file);
			data.append("mypic",blob,file.file.name);
		}else{
			data.append("mypic",file.file,file.file.name);
		}
		
		const token:any=localStorage.getItem('token');
		if(token){
			data.append("token", token);
		}
		return this.apiAxios({ baseURL, headers, method: 'post', url, data, params,responseType});
  }
  
  protected filePress(file:any){
	  debugger;
	  return new Promise((resolve, reject)=>{
	  const reader=new FileReader();
	  reader.readAsDataURL(file);
	  const canvas=document.getElementById('canvas');
	  const ctx=(canvas as any).getContext('2d');
	  
	  const img = new Image();
	 // const dataURL:string;
	  reader.onload=(event)=>{
		const content=(event as any).target.result; //图片的src，base64格式
		img.src=content;
		img.onload=()=>{ //图片加载完毕
		    const initSize=img.src.length;
		 	const width=img.width;
		 	const height=img.height;
			(canvas as any).width=width;
			(canvas as any).height=height;
			ctx.fillStyle = 'rgba(255, 255, 255, 0)';
		 	ctx.drawImage(img, 0, 0, width, height);
		 	const dataURL = (canvas as any).toDataURL("image/jpeg", 0.8);
			const blob=this.dataURItoBlob(dataURL);
			resolve(blob);
		}
	 };
	 });
  }
  	
	protected dataURItoBlob(dataurl:any){   //dataurl是base64格式
        const arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]);
		let  n:number = bstr.length;
		const u8arr = new Uint8Array(n);
        while(n--){
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], {type:mime});
    }
	
  
}
export default Abstract;