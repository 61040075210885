import axios, { AxiosRequestConfig, Method } from 'axios';
import { Toast } from 'vant';
import router from '../router'

Toast.allowMultiple();

// 定义接口
interface PendingType {
    url?: string;
    method?: Method;
    params: any;
    data: any;
    cancel: any;
}

// 取消重复请求
const pending: Array<PendingType> = [];

let loadingInstance:any;

const CancelToken = axios.CancelToken;
// axios 实例
const instance = axios.create({
    timeout: 100000,
    responseType: 'json',
	// transformRequest: [function (data) {
	//         // 对 data 进行任意转换处理
	//         return Qs.stringify(data)
	// }]
});

// 移除重复请求
const removePending = (config: AxiosRequestConfig) => {
    for (const key in pending) {
        const item: number = +key;
        const list: PendingType = pending[key];
        // 当前请求在数组中存在时执行函数体
        if (list.url === config.url && list.method === config.method && JSON.stringify(list.params) === JSON.stringify(config.params) && JSON.stringify(list.data) === JSON.stringify(config.data)) {
            // 执行取消操作
			list.cancel('操作太频繁，请稍后再试');
            console.log('操作太频繁，请稍后再试');
            // 从数组中移除记录
            pending.splice(item, 1);
        }
    }
};


// 添加请求拦截器
instance.interceptors.request.use(
    request => {
		if(loadingInstance){
			loadingInstance.clear();
		}
		loadingInstance=Toast.loading({
		  message: '加载中...',
		  forbidClick: true,
		  duration:0
		});   
		removePending(request);
		request.cancelToken = new CancelToken((c) => {
		    pending.push({ url: request.url, method: request.method, params: request.params, data: request.data, cancel: c });
		});
        return request;
    },
    error => {
		//// 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 添加响应拦截器
instance.interceptors.response.use(
    response => {
		loadingInstance.clear();
		removePending(response.config);
		try{
			if(!response.data.status&&response.data.data.error_code=='token_error'){
				window.localStorage.removeItem('token');
				router.replace({
					path:'/phoneLogin'
				});
			}
		}catch(e){
			console.log("kk")	
		}
		//// 对响应数据做点什么		
        return response;
    },
    error => {
		//// 对响应错误做点什么
        // eslint-disable-next-line
        return Promise.reject(error || {message: error.message});
    }
);

export default instance;