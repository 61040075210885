import Abstract from '@/service/abstract';
class Basic extends Abstract {
    /**
     * 登录
     * @param {string} account 用户
     * @param {string} password 密码
     * @param {string} captchaCode 图形码
     * @param {string} captchaCodeToken 图形码token
     */
	
	//获取手机验证码(注册)
	protected PHONECODE='apiNew.php?m=ApiNew&c=Account&a=getPhoneCodeReg';
	
	//获取手机验证码(登录，忘记密码)
	protected CODEOTHER='apiNew.php?m=ApiNew&c=Account&a=getPhoneCodeOther';
	
	//忘记密码
	protected FINDPWD='apiNew.php?m=ApiNew&c=Account&a=findPwd';
	
	// 密码登录
	protected PSWLOGIN='/apiNew.php?m=ApiNew&c=Account&a=login';
	
	//短信验证码登录
	protected SMSLOGIN='/apiNew.php?m=ApiNew&c=Account&a=smsLogin';
	
	//获取验证码key
	protected GETKEY='/apiNew.php?m=ApiNew&c=Account&a=getKey';
	
	//图片验证码
	protected WEBCODE='/apiNew.php?m=ApiNew&c=Account&a=getWebCode';
	
	//手机注册
	protected PHONEREGISTER='/apiNew.php?m=ApiNew&c=Account&a=phoneRegister';
	
	//置顶链接
	protected TOPLIST='/apiNew.php?m=ApiNew&c=Index&a=topList';
	
	//帖子列表
	protected NEWLIST='/apiNew.php?m=ApiNew&c=Index&a=index';	
	
	//查看帖子详情
	protected VIEWTHREAD='/apiNew.php?m=ApiNew&c=Post&a=viewThread';
	
	//获取主题分类
	protected GETTYPES='apiNew.php?m=ApiNew&c=Post&a=getTypes';
	
	//获取子板块列表
	protected SUBINDEX='apiNew.php?m=ApiNew&c=Index&a=subIndex';
	
	//上传图片
	protected UPLOADIMG='apiNew.php?m=ApiNew&c=Post&a=uploadImg';
	
	//发布内容
	protected NEWTHREAD='apiNew.php?m=ApiNew&c=Post&a=newThread';
	
	//个人中心
	protected INFO='apiNew.php?m=ApiNew&c=Member&a=info';
	
	//修改个人信息
	protected EDITINFO='apiNew.php?m=ApiNew&c=Member&a=editInfo';
	
	//收藏操作
	protected FAVORITE='/apiNew.php?m=ApiNew&c=Post&a=favorite';
	
	//点赞操作
	protected DOLIKES='/apiNew.php?m=ApiNew&c=Post&a=do_likes';
	
	//回帖
	protected REPLYTHREAD='/apiNew.php?m=ApiNew&c=Post&a=replyThread';
	
	//举报
	protected REPORTPOST='apiNew.php?m=ApiNew&c=Post&a=reportPost';
	
	//获取举报分类
	protected REPORTTYPES='apiNew.php?m=ApiNew&c=Post&a=getReportTypes';
	
	//回帖的回复
	protected REPLYPOST='apiNew.php?m=ApiNew&c=Post&a=replyPost';
	
	//收藏列表
	protected FAVORITELIST='apiNew.php?m=ApiNew&c=Member&a=favoriteList';
	
	//评论回复列表
	protected REPLYLIST='apiNew.php?m=ApiNew&c=Member&a=replyList';
	
	//粉丝列表
	protected FOLLOWERLIST='apiNew.php?m=ApiNew&c=Member&a=followerList';
	
	//关注列表
	protected FOLLOWLIST='apiNew.php?m=ApiNew&c=Member&a=followList';
	
	//关注操作
	protected FOLLOW='apiNew.php?m=ApiNew&c=Member&a=follow';
	
	//查看回复的回复
	protected VIEWPOSTREPLY='apiNew.php?m=ApiNew&c=Post&a=viewPostReply';
	
	//搜索接口
	protected SEARCH='apiNew.php?m=ApiNew&c=Index&a=search';
	
	//获取头像列表
	protected AVATARLIST='apiNew.php?m=ApiNew&c=Member&a=avatarList';
	
	//设置头像
	protected SETAVATAR='apiNew.php?m=ApiNew&c=Member&a=setAvatar';
	
}
// 单列模式返回对象
let instance:any;
export default (() => {
    if (instance) return instance;
    instance = new Basic();
    return instance;
})();